!(function (a) {})(jQuery);

window.hideLoader = function () {
  setTimeout(function () {
    const a = jQuery;
    a('body').hasClass('side-header')
      ? a('.smooth-scroll').on('click', function () {
          event.preventDefault();
          var t = a(this).attr('href');
          a('html, body')
            .stop()
            .animate({ scrollTop: a(t).offset().top }, 1500, 'easeInOutExpo');
        })
      : a('.smooth-scroll').on('click', function () {
          event.preventDefault();
          var t = a(this).attr('href');
          a('html, body')
            .stop()
            .animate(
              { scrollTop: a(t).offset().top - ($(window).width() > 576 ? 50 : 300) },
              1500,
              'easeInOutExpo',
            );
        }),
      a('.navbar-toggler').on('click', function () {
        a(this).toggleClass('show');
      }),
      a('.navbar-nav a').on('click', function () {
        a('.navbar-collapse, .navbar-toggler').removeClass('show');
      }),
      a('.navbar-side-open .collapse, .navbar-overlay .collapse').on(
        'show.bs.collapse hide.bs.collapse',
        function (a) {
          a.preventDefault();
        },
      ),
      a('.navbar-side-open [data-toggle="collapse"], .navbar-overlay [data-toggle="collapse"]').on(
        'click',
        function (t) {
          t.preventDefault(), a(a(this).data('target')).toggleClass('show');
        },
      ),
      a('.owl-carousel').each(function (t) {
        var e = a(this);
        a(this).owlCarousel({
          autoplay: e.data('autoplay'),
          center: e.data('center'),
          autoplayTimeout: e.data('autoplaytimeout'),
          autoplayHoverPause: e.data('autoplayhoverpause'),
          loop: e.data('loop'),
          speed: e.data('speed'),
          nav: e.data('nav'),
          dots: e.data('dots'),
          autoHeight: e.data('autoheight'),
          autoWidth: e.data('autowidth'),
          margin: e.data('margin'),
          stagePadding: e.data('stagepadding'),
          slideBy: e.data('slideby'),
          lazyLoad: e.data('lazyload'),
          navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
          animateOut: e.data('animateOut'),
          animateIn: e.data('animateIn'),
          video: e.data('video'),
          items: e.data('items'),
          responsive: {
            0: { items: e.data('items-xs') },
            576: { items: e.data('items-sm') },
            768: { items: e.data('items-md') },
            992: { items: e.data('items-lg') },
          },
        });
      }),
      a(function () {
        a(window).on('scroll', function () {
          a(this).scrollTop() > 400 ? a('#back-to-top').fadeIn() : a('#back-to-top').fadeOut();
        });
      }),
      a('#back-to-top').on('click', function () {
        return a('html, body').animate({ scrollTop: 0 }, 'slow'), !1;
      });
  }, 100);
};
